<template>
  <layout page="news-form">
    <page-title :icon="`lni lni-${id > 0 ? 'pencil' : 'plus'}`" :breadcrumb="breadcrumb">
      {{ id > 0 ? 'Editar Noticia' : 'Nova Noticia' }}
    </page-title>
    <div class="card">
      <div class="card-content">
        <div class="form">
          <error :error="error" />

          <div class="field">
            <label class="label" for="title">Título<asterisk /></label>
            <div class="control">
              <input v-model="title" class="input" type="text" name="title" id="title" placeholder="Titulo da noticia" ref="title" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="tags">Tags</label>
            <div class="control">
              <input @change="tagsHandle" class="input" type="tags" name="tags" id="tags" placeholder="Tags" ref="tags" />
            </div>
            <p class="help">Digite os valores separados por vírgula</p>
          </div>
          <div class="field">
            <label class="label" for="text">Noticia</label>
            <markdown-editor v-model="text" name="text" id="text" placeholder="Descrição da atividade" />
            <p class="help">Markdown syntax disponível, <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">veja mais</a>.</p>
          </div>
          <div class="field">
            <label class="label" for="author">Autor<asterisk /></label>
            <div class="control">
              <input v-model="author" class="input" type="text" name="author" id="author" placeholder="Autor da noticia" ref="author" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="thumbnail">Imagem<asterisk /></label>
            <div class="file" :class="{'has-name': hasFile}">
              <label class="file-label">
                <input
                  class="file-input"
                  type="file"
                  name="thumbnail"
                  id="thumbnail"
                  @change="upload"
                  ref="thumbnail"
                  accept="image/png,image/jpeg,image/jpg"
                >
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="lni lni-upload"></i>
                  </span>
                  <span class="file-label">
                    Escolher arquivo...
                  </span>
                </span>
                <span v-if="hasFile" class="file-name">
                  {{ thumbnail }}
                </span>
                <span>
                  <button v-if="hasFile" class="button is-danger file-button" @click="removeFile">
                    <i class="lni lni-close" />
                  </button>
                </span>
              </label>
            </div>
            <p class="help">Tipos permitidos: PNG, JPG, JPEG</p>
          </div>

          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send()">Salvar Noticia</button>
            </div>
            <div class="control">
              <router-link class="button is-link is-light" to="/admin/noticias">Cancelar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import BulmaTagsInput from '@creativebulma/bulma-tagsinput'
import SwalLoading from '@/helpers/SwalLoading'
import { DASHBOARD, NEWS_LIST, NEWS_FORM } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import Error from '@/components/ui/Error'
import MarkdownEditor from '@/components/MarkdownEditor'
import Asterisk from '@/components/ui/Asterisk'

export default {
  name: 'NewsForm',
  components: {
    Layout,
    PageTitle,
    Error,
    MarkdownEditor,
    Asterisk
  },
  created () {
    if (this.$route.params.id !== undefined) {
      SwalLoading.fire()

      api.get(`/news/${this.$route.params.id}`).then(res => {
        this.id = res.data.id
        this.thumbnail = res.data.thumbnail
        this.title = res.data.title
        this.text = res.data.text
        this.author = res.data.author
        this.hasFile = true

        this.addTags(res.data.tags)
        SwalLoading.close()
      }).catch(() => {
        this.error = 'Não foi possível carregar os dados'
        SwalLoading.close()
      })
    }
  },
  mounted () {
    this.tagsInputs = new BulmaTagsInput(this.$refs.tags)
    this.tagsInstance = this.$refs.tags.BulmaTagsInput()
  },
  data () {
    return {
      id: 0,
      thumbnail: '',
      title: '',
      text: '',
      author: '',
      tags: '',
      tagsInputs: null,
      error: null,
      enviando: false,
      hasFile: false,
      tagsInstance: null
    }
  },
  computed: {
    breadcrumb () {
      const FORM = { ...NEWS_FORM, isActive: true }
      if (this.id > 0) FORM.text = 'Editar Noticia'

      return [
        DASHBOARD,
        NEWS_LIST,
        FORM
      ]
    }
  },
  methods: {
    upload () {
      this.thumbnail = this.$refs.thumbnail.value
      this.hasFile = this.thumbnail !== ''
    },
    removeFile () {
    },
    tagsHandle (e) {
      this.tags = e.target.value
    },
    addTags (tags) {
      tags.split(',').map(tag => {
        this.tagsInstance.add(tag)
      })
    },
    send () {
      this.enviando = true

      if (this.title === '') {
        return this.fieldInvalid('title', 'Título é obrigátorio')
      }
      this.error = null

      if (this.author === '') {
        return this.fieldInvalid('author', 'Autor é obrigátorio')
      }
      this.error = null

      if (this.thumbnail === '') {
        return this.fieldInvalid('thumbnail', 'Thumbnail é obrigátoria')
      }
      this.error = null

      const formData = this.formDataSet()
      SwalLoading.fire()

      if (this.id === 0) {
        api.post('/news', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          SwalLoading.close()
          if (res.status === 201) {
            this.$router.push('/admin/noticias')
          } else {
            this.enviando = false
            this.error = 'Não foi possível criar a noticia'
          }
        }).catch(err => {
          SwalLoading.close()
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      } else {
        api.patch(`/news/${this.id}`, {
          title: this.title,
          text: this.text,
          author: this.author,
          tags: this.tags
        }).then(res => {
          this.enviando = false
          SwalLoading.close()
          this.$router.push('/admin/noticias')
        }).catch(err => {
          SwalLoading.close()
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      }
    },
    fieldInvalid (ref, message) {
      this.error = message
      this.$refs[ref].focus()
      this.enviando = false
    },
    formDataSet () {
      const formData = new FormData()
      formData.append('thumbnail', this.$refs.thumbnail.files[0])
      formData.append('title', this.title)
      formData.append('text', this.text)
      formData.append('author', this.author)
      formData.append('tags', this.tags)

      return formData
    }
  }
}
</script>
